// Migrated
<template>
  <div class="charter-search__header flex">
    <h5 class="mb-0 font-medium">
      <slot />
    </h5>
    <small
      v-if="selectError"
      class="ml-4 text-error blink blink-title block px-2 rounded-[1.25rem]"
    >{{ selectError }}</small>
    <slot name="content" />
    <div class="grow" />
    <button
      v-if="showClose"
      class="btn py-0 block lg:hidden m-0 text-[20px]"
      type="button"
      @click="$emit('close')"
    >
      <fa icon="times" />
    </button>
    <button
      v-if="showNext"
      class="btn-rounded btn-orange px-[40px] py-2 capitalize text-xl font-medium"
      type="button"
      @click="$emit('next')"
    >
      {{ $t('formNextButton') }}
    </button>
    <button
      v-if="campaign"
      class="btn-rounded btn-orange px-[40px] py-2 capitalize text-xl font-medium"
      type="button"
      @click="$emit('next')"
    >
      Sök
    </button>
  </div>
</template>

<script>
export default defineNuxtComponent({
  props: {
    selectError: {
      type: String,
      default: null,
    },

    campaign: {
      type: Boolean,
      default: false,
    },

    showNext: {
      type: Boolean,
      default: false,
    },

    showClose: {
      type: Boolean,
      default: true,
    },
  },

  emits: ['close', 'next'],
})
</script>
